import amped from '../assets_new/amped.png'
import reddit from '../assets_new/redditmini.png'
import battleship from '../assets_new/battleship.png'
import wishlist from '../assets_new/wishlist.png'

export interface ProjectType {
  name: string,
  type: string, 
  description: string,
  tech: string[],
  imagesrc: string,
  liveUrl: string,
  githubUrl: string,
}

export const projects: ProjectType[] = [
  {
    name: "AMPED",
    type: 'individual',
    description: 'Track your workouts, sets & reps, training goals and more with this web app designed for mobile.',
    tech: ['Typescript', 'React', 'Redux', 'HTML', 'CSS', 'Express.js', 'PostgreSQL', 'Passport.js'],
    imagesrc: amped,
    liveUrl: 'https://ampedpro.netlify.app/login',
    githubUrl: 'https://github.com/ryanhartwig/amped',
  },
  {
    name: "RedditMini",
    type: 'individual',
    description: 'Browse Reddit with style in this minified, highly customizable Reddit client.',
    tech: ['Typescript', 'React', 'Redux', 'HTML', 'CSS'],
    imagesrc: reddit,
    liveUrl: 'https://ryanhartwig.github.io/RedditMini',
    githubUrl: 'https://github.com/ryanhartwig/RedditMini',
  },
  {
    name: "Battleship",
    type: 'collaborative',
    description: 'In this mobile battleship client, users can easily track ship placement, attacks, upgrades, and more.',
    tech: ['Typescript', 'React', 'Redux', 'HTML', 'CSS'],
    imagesrc: battleship,
    liveUrl: 'https://ryanhartwig.github.io/battleship',
    githubUrl: 'https://github.com/ryanhartwig/battleship',
  },
  {
    name: "Wishlist",
    type: 'individual',
    description: 'Created last minute to help our family give additional gift ideas to each other.',
    tech: ['Typescript', 'React', 'Firebase', 'HTML', 'CSS'],
    imagesrc: wishlist,
    liveUrl: 'https://ryanhartwig.github.io/xmas-gift-list',
    githubUrl: 'https://github.com/ryanhartwig/xmas-gift-list',
  },
]