import './Links.css'

import { ReactComponent as Github } from '../assets_new/svg/github.svg';
import { ReactComponent as Email } from '../assets_new/svg/email.svg';
import { ReactComponent as LinkedIn } from '../assets_new/svg/linkedin.svg';
import clsx from 'clsx';

interface LinksProps {
  spread?: boolean,
  trigger?: boolean,
}

export const Links: React.FC<LinksProps> = ({spread, trigger = false}) => {


  
  return (
    <div className={clsx('Links', {'in-top': trigger})} style={{justifyContent: spread ? 'space-between' : ''}}>
      <p>ryanhartwig {!spread && <span className='dot'>•</span>}</p>
      <div className='Links-icons'>
        <a href='https://www.linkedin.com/in/ryan-hartwig-a97818264/' rel='noreferrer' target={'_blank'}><LinkedIn shapeRendering={'geometricPrecision'} className='Contact-link' /></a>
        <a href='https://github.com/ryanhartwig' rel='noreferrer' target={'_blank'}><Github shapeRendering={'geometricPrecision'} className='Contact-link' /></a>
        <a href="mailto:ryan1431@gmail.com" rel='noreferrer' target={'_blank'}><Email shapeRendering={'geometricPrecision'} className='Contact-link' /></a>
      </div>
    </div>
  )
}