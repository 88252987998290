
import { useCallback, useRef, useState } from 'react'
import './Contact.css'

import clsx from 'clsx';

interface ContactProps {
  trigger?: boolean,
}

export const Contact: React.FC<ContactProps> = ({trigger = true}) => {
  
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(undefined!);
  
  const onMessageSend = useCallback((e: any) => {
    e.preventDefault();
    
    setDisabled(true);
    setError(false);
    setSuccess(false);

    ;(async () => {

      try {
        const response = await fetch('https://public.herotofu.com/v1/c4fd95a0-a275-11ed-a31e-753411848f80', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            email,
            message
          }),
        });

        if (!response.ok) {
          setDisabled(false);
          setError(true);
          return;
        }

        setName('');
        setEmail('');
        setMessage('');
        setDisabled(false);
        setSuccess(true);

      } catch(e) {
        console.error(e);

        setDisabled(false);
        setError(true);
      }
    })()
  }, [email, message, name]);
      
  return (
    <div className="Contact">
      <form ref={formRef} className='Contact-form' onSubmit={onMessageSend}>
        <input value={name} onChange={(e) => setName(e.target.value)} placeholder='name' className={clsx('Contact-name', {trigger})} required />
        <input value={email} type='email' onChange={(e) => setEmail(e.target.value)} placeholder='email' className={clsx('Contact-email', {trigger})} required />
        <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder='message' className={clsx('Contact-message', {trigger})} required  />
        <div className='form-action'>
          <input type="submit" value='Send Message' className={clsx('submit', {disabled})} />
        </div>
      </form>
      <p style={{maxHeight: success ? '' : '0px'}} className='Contact-success'>Thanks for reaching out! I'll get back to you as soon as I can.</p>
      <p style={{maxHeight: error ? '' : '0px'}} className='Contact-error'>Something went wrong, please try again or reach me through one of the links below.</p>
    </div>
  )
}