import './reset.css';
import './App.css';
import './AppTransitions.css';

import { Links } from './components/Links';

import Headshot from './assets_new/headshot.png';
import { technologies } from './data/technologies';
import { projects } from './data/projectData';

import { Contact } from './components/Contact';
import { Circles } from './visual/Circles';
import { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Project } from './components/Project';

function App() {

  const [introTrigger, setIntroTrigger] = useState(false);

  useMemo(() => setTimeout(() => setIntroTrigger(true), 1), []);
  
  
  return (
    <div className="App hidescrollbar">
      <article className='App-content'>
        <Links trigger={introTrigger} />
        {/* Photo */}
        <div className={clsx('App-photo-box', {'trigger': introTrigger})}>
          <img src={Headshot} alt="Headshot of me" className={clsx('headshot', {'trigger': introTrigger})} />
        </div>
        
        {/* Intro */}
        <section id="intro">
          <h1 className={clsx('intro-text', {'in-top': introTrigger})}>
            Hello! <br/>
            I'm <div className='intro-name'>Ryan Hartwig<div className='intro-line' /></div>
          </h1>
          <p className={clsx('intro-desc', {'in-left': introTrigger})}>
            I am a Hamilton-based web developer with a passion for creating intuitive, visually appealing, and functional websites.
          </p>
          <div className={clsx('intro-contact', {'in-left': introTrigger})}>
            <a href="#contact">Get in Touch</a>
          </div>
        </section>

        <hr />

        {/* Technologies */}
        <section id="technologies">
          {technologies.map(t => <h3 key={t}>{t}</h3>)}
        </section>
        
        <h2>Projects</h2>
        <section id="projects">
          {projects.map((p, i) => <Project key={p.name} project={p} index={i} />)}
        </section>

        <section id="contact">
          <div>
            <h2>Contact</h2>
            <p style={{margin: 20, fontSize: 20}}>Looking for more info? <br /><br />Leave me a message and I'll get back to you as soon as possible!</p>
          </div>
          <div className='contact-form'>
            <Contact />
          </div>
        </section>
        <hr style={{margin: 0}}/>
        <Links trigger spread />


        {/* Background */}
        <Circles top={-310} left={-540}/>
        <Circles top={800} right={-650}/>
        <Circles bottom={'8%'} left={-720}/>
        
        
      </article>

      {/* Background skew */}
      <div className='bg-rect'>
          <div className='bg-top'/>
          <div className='bg-bottom'/>
        </div>
    </div>
  );
}

export default App;
