export const technologies = [
  'HTML',
  'CSS',
  'Typescript',
  'React',
  'Redux',
  'Git',
  'CLI',
  'Node',
  'Express',
  'REST API',
  'Passport',
  'PostgreSQL',
]