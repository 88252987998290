import { ProjectType } from '../data/projectData'
import './Project.css'
import { ReactComponent as Github } from '../assets_new/svg/github.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

interface ProjectProps {
  project: ProjectType,
  index: number,
}

export const Project: React.FC<ProjectProps> = ({project, index}) => {
  const [trigger, setTrigger] = useState<boolean>(false);

  const left = index % 2 ? 60 : -60;

  const triggerRef = useRef<HTMLDivElement>(undefined!);

  // Trigger subsequent transitions when in viewport
  const onVisible = useCallback((entries: IntersectionObserverEntry[]) => {
    entries.forEach(e => {
      if (e.isIntersecting) {
        setTrigger(true);
      }
    })
  }, []);
  
  useEffect(() => {
    const observer = new IntersectionObserver(onVisible);
    const trigger = triggerRef.current;
    
    observer.observe(trigger);
    return () => {
      observer.unobserve(trigger);
    }
  }, [onVisible]);
  
  return (
    <div key={project.name} 
      className={clsx('project', {trigger})} 
      ref={triggerRef}
      style={{left}}
    >
      <img src={project.imagesrc} alt={`${project.name} preview`} />
      <div className='project-details'>
        <div>
          <h2>{project.name}</h2>
          <p>{project.type}</p>
        </div>
        <div className='project-links noselect'>
          <a href={project.liveUrl} target="_blank" rel="noreferrer">
            <div className='project-link'>
              <div className='project-icon'>
                <div className='live' />
              </div>
              <p>View Live Site</p>
            </div>
          </a>
          <a href={project.githubUrl} target="_blank" rel="noreferrer">
            <div className='project-link'>
              <Github className='project-icon' />
              <p>Github Repo</p>
            </div>
          </a>
        </div>
        <div>
          <p className='project-description'>{project.description}</p>
          <p className='project-tech'>{project.tech.join(' • ')}</p>
        </div>
      </div>
    </div>
  )
}