import './Circles.css'

interface CirclesProps {
  top?: string | number,
  left?: string | number,
  right?: string | number,
  bottom?: string | number,
}

export const Circles: React.FC<CirclesProps> = (position) => {
  const circles = new Array(4).fill(0);
  
  return (
    <div className="Circles" style={{...position}}>
      {circles.map((_, i) => {
        const size = Math.pow(i + 5, 3.4);
        const [width, height] = [size, size];
        const opacity = 1 - (0.2 * i);
        const rotate = (i % 2) * 180 + 'deg';
        const animationDelay = (i % 2) * -30 + 's';
        
        return (
        <div className='circle-wrapper'>
          <div className='circle' style={{animationDelay, width, height, rotate, opacity}} />
        </div>
      )})}
    </div>
  )
}